<template>
  <div class="lml">
    <div class="ad" v-if="lightBanner">
      <img :src="lightBanner.webImg" />
    </div>
    <div class="content-box">
        <h2></h2>
        <div :class="'lml-box ' + ('box-' + (index+1) )" v-for="item,index in lightMobile" :key="index" :id="'box' + (index+1)">
            <img :src="item.webImg" />
            <div class="content">
                <h3>{{item.title}}</h3>
                <p>
                    <span class="desc" v-for="_item in item.subTitle.split('\n')">
                      {{_item}}
                    </span>
                </p>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
import Utils from '@/api/Utils.js' 
export default {
  name: 'Lml',
  components: {
    
  },
  data(){
    return {
        lightBanner: null,
        lightMobile: null
    }
  },
  mounted(){
    Utils.post(`v1/outer/article/light`).then((res)=>{
      if(res && res.code == 200){
        this.lightBanner = res.data.lightBanner
        this.lightMobile = res.data.lightMobile
        if(this.$route.hash){
          setTimeout(()=>{
            this.$nextTick(()=>{
              location.href = this.$route.hash; 
            })
          },200)
        }
      }
    })
  }
}
</script>
<style scoped lang="stylus">
.lml{
  background : #f7f8f8;
  padding-bottom: 120px;
}
.ad{
   height: 538px;
   overflow: hidden;
   position relative
   img{
      display block 
      height: 538px;
      margin: 0 auto;
      position absolute
      left: 50%
      transform translate3d(-50%,0,0)
      top: 0
   }
}
.content-box{
    padding-top 90px
}
h2{
  margin: 0 0 73px;
  background: url('./title.png') 50% 0 no-repeat;
  background-size: auto 31px
  height 31px;
}
.lml-box{
    width 853px
    position relative
    margin: 0 auto 96px
    img{
        display block
        width 853px
    }
    .content{
        position absolute
        z-index: 2
        top: 99px
        line-height 22px
        font-size: 12px
    }
    h3{
        margin-bottom 28px
        font-weight bold
    }
    span{
      display block;
    }
    &.box-1{
        .content{
            left: 567px
            width 274px
        }
        
    }
    &.box-2{
        .content{
            left: 0
            width 260px
            top: 110px
        }
        
    }
    &.box-3{
        margin-bottom 0
        .content{
            left: 567px
            width 260px
            top: 105px
        }
        
    }
}

</style>
